import React from 'react';

import { createRoot } from 'react-dom/client';

import {
  ENV_CONFIG_BUILD_APP_NAME,
  ReactRootAppName,
} from './common/constants/env';

const LazyPmAppIndex = React.lazy(() => import('./components/pm'));
const LazyPmClientAppIndex = React.lazy(() => import('./components/pm-client'));

const getLazyAppIndexComponent = () => {
  const environmentsToIndexPathMap: Record<
    ReactRootAppName,
    React.LazyExoticComponent<() => JSX.Element>
  > = {
    PM: LazyPmAppIndex,
    PM_CLIENT_PORTAL: LazyPmClientAppIndex,
  };

  const rootAppName = ENV_CONFIG_BUILD_APP_NAME;

  return rootAppName
    ? environmentsToIndexPathMap[rootAppName] || environmentsToIndexPathMap.PM
    : environmentsToIndexPathMap.PM;
};

const LazyAppIndex = getLazyAppIndexComponent();

const container = document.getElementById('root');

if (!container) {
  console.error('Root container not found');
} else {
  const root = createRoot(container);

  root.render(
    <React.Suspense fallback={<div>...</div>}>
      <LazyAppIndex />
    </React.Suspense>,
  );
}
